module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-4VS3CVQBCM"]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-cloudflare-web-analytics/gatsby-browser.js'),
      options: {"plugins":[],"token":"f9549992306643e7a41201a0c651be7a"},
    },{
      plugin: require('../../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://e781f43536954a7d8490b2a1b679be30@o24217.ingest.sentry.io/6467610"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
